import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import SaidBar from './SaidBar';

export default function Newclients() {


    $(window).on('resize', function () {
        if ($(window).width() < 1050) {
            $(".offcanvas").removeClass("show")
            $(".fa-xmark").removeClass("d-none")
        }
        else {
            $(".offcanvas").addClass("show")
            $(".fa-xmark").addClass("d-none")
        }
    });
    return (
        <>
            <div className="container">
                <div className="row">
                    <form>
                        <div className="col-sm-8 m-auto">
                            <div className="d-flex mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='FName..' />
                                <input type="text" className='form-control w-50 m-1' placeholder='LName..' />
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="px-1 mb-3">
                                <select class="form-select" aria-label="Default select example">
                                    <option value="1">Itin</option>
                                    <option value="2">Ssn</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="d-flex mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Birth..' />
                                <input type="text" className='form-control w-50 m-1' placeholder='Death..' />
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="d-flex mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Phone..' />
                                <input type="text" className='form-control w-50 m-1' placeholder='Mobile..' />
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="d-flex mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Occuption..' />
                                <select class="form-select w-50 m-1" aria-label="Default select example">
                                    <option value="1">Itin</option>
                                    <option value="2">Ssn</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div class="input-group px-1 mb-3">
                                <input type="text" aria-label="Adrres" placeholder='Adrres' class="form-control" />
                                <input type="text" aria-label="Zip" placeholder='Zip' class="form-control" />
                                <input type="text" aria-label="St.Name" placeholder='St.Name' class="form-control" />
                                <input type="text" aria-label="City" placeholder='City' class="form-control" />
                                <input type="text" aria-label="Country" placeholder='Country' class="form-control" />
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="m-1 mb-3">
                                <select class="form-select" aria-label="Default select example">
                                    <option value="1">student</option>
                                    <option value="2">student 2</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-warning'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
