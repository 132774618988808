import React from 'react'

export default function Newcompany() {
    return (
        <>

            <div className="container">
                <div className="row">
                    <form >
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='EIN..' />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Name..' />
                            </div>
                        </div>
                        <div className="col-sm-12 ">
                            <div className=" d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Address..' />

                            </div>
                        </div>
                        <div className="col-sm-12 ">
                            <div className=" d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='City..' />

                            </div>
                        </div>

                        <div className="col-sm-12 ">
                            <div className=" d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Email..' />

                            </div>
                        </div>
                        <div className="col-sm-12 ">
                            <div className=" d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Number..' />

                            </div>
                        </div>
                        <div className="col-sm-12 ">
                            <div className=" d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Fax..' />

                            </div>
                        </div>
                        <div className="col-sm-12 ">
                            <div className=" d-flex justify-content-center mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Bank Info..' />

                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-warning'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
