import React from 'react'

export default function Depends() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <form>
                        <div className="col-sm-8 m-auto">
                            <div className="d-flex mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='FName..' />
                                <input type="text" className='form-control w-50 m-1' placeholder='LName..' />
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="px-1 mb-3">
                                <select class="form-select" aria-label="Default select example">
                                    <option value="1">Itin</option>
                                    <option value="2">Ssn</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="d-flex mb-3">
                                <input type="text" className='form-control w-50 m-1' placeholder='Birth..' />
                                <input type="text" className='form-control w-50 m-1' placeholder='Death..' />
                            </div>
                        </div>
                        <div className="col-sm-8 m-auto">
                            <div className="px-1 mb-3">
                                <select class="form-select" aria-label="Default select example">
                                    <optgroup label="Relation">
                                        <option>son</option>
                                        <option>daughter</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-warning'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>


        </>
    )
}
