import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'


export default function SaidBar() {

    $(window).on('resize', function () {
        if ($(window).width() < 1050) {
            $(".offcanvas").removeClass("show")
            $(".fa-xmark").removeClass("d-none")
        }
        else {
            $(".offcanvas").addClass("show")
            $(".fa-xmark").addClass("d-none")
        }
    });

    // $(window).ready(function () {
    //     $(".offcanvas").addClass("show")
    // })

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <i className="fa-solid fa-bars fs-1 mt-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" ></i>
                        <div className="offcanvas offcanvas-start bg-black show" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                            <div className="offcanvas-header">
                                <div className="d-flex">
                                    <i className="fa-regular fa-note-sticky text-warning fs-2"></i>
                                    <p className='ps-3 fs-4 text-white'>C.R.M</p>
                                </div>
                                <i class="fa-solid fa-xmark fs-4 text-white pointer mb-3 d-none" data-bs-dismiss="offcanvas" aria-label="Close"></i>
                            </div>
                            <div class="offcanvas-body">
                                <ul className="text-light list-unstyled">
                                    <li className="p-3 pe-lg-5 sidebar-element">
                                        <Link to="Company" className="nav-link"> <i className="bi-house" /><span className="">Companies</span> </Link>
                                    </li>
                                    <li className="p-3 pe-lg-5 sidebar-element">
                                        <Link to="clientspage" className="nav-link"> <i className="bi-house" /><span className="">Clients</span> </Link>
                                    </li>
                                    <li className="p-3 pe-lg-5 sidebar-element">
                                        <Link to="#" className="nav-link"> <i className="bi-house" /><span className="">Calls</span> </Link>
                                    </li>
                                    <li className="p-3 pe-lg-5 sidebar-element">
                                        <Link to="#" className="nav-link"> <i className="bi-house" /><span className="">Schedule</span> </Link>
                                    </li>
                                    <li className="p-3 pe-lg-5 sidebar-element">
                                        <Link to="#" className="nav-link"> <i className="bi-house" /><span className="">Services</span> </Link>
                                    </li>
                                    <li className="p-3 pe-lg-5 sidebar-element">
                                        <Link to="#" className="nav-link"> <i className="bi-house" /><span className="">Users</span> </Link>
                                    </li>

                                    <li className="p-3 pe-lg-5 sidebar-element">
                                        <Link to="/" className="nav-link"> <i className="bi bi-box-arrow-left"></i><span className="">Logout</span> </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              
        </>
    )
}
