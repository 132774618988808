import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Layout from './Layout'
import Login from './Login'
import Notfound from './Notfound'
import Clientspage from './Clientspage'
import Newclients from './Newclients'
import Spouse from './Spouse'
import Depends from './Depends'
import Company from './Company'
import Newcompany from './Newcompany'




  const routers = createBrowserRouter([
    
    {index:true ,element:<Login/>},
    {
    path:"", element:<Layout/> , children:[
      {path:"clientspage",element:<Clientspage/>},
      {path:"newclients",element:<Newclients/>},
      {path:"spouse",element:<Spouse/>},
      {path:"depends",element:<Depends/>},
      {path:"company",element:<Company/>},
      {path:"newcompany",element:<Newcompany/>},
      {path:"*",element:<Notfound/>},
    ]
  }]) 



  function App() {
    return (
      <>
      <RouterProvider router={routers}/>
      </>
    );
  }
  
  export default App;