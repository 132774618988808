import React from 'react'
import { Link } from 'react-router-dom'
import login from './images/sew-5034027_640.png'
import { useFormik } from 'formik'

export default function Login() {
    return (
        <>
            <li className="fixed-top p-3 pe-lg-5 d-lg-flex d-none  ">
                <i className="fa-regular fa-note-sticky text-warning fs-2"></i>
                <p className='ps-2 fs-4 fw-bold'>Notes</p>
            </li>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 d-none d-lg-flex justify-content-center align-items-center">
                        <img className='w-100 p-5' src={login} alt="" />
                    </div>


                    <div className="col-lg-7">
                        <div className='min-vh-100 d-flex justify-content-center align-items-center text-center signup-container'>
                            <div className='bg-light bg-opacity-25 shadow w-100 mx-auto  p-5 rounded-2'>
                                <h1 className='fw-bold'>Sign Up Now</h1>
                                <div className='pt-3'>
                                    <form>
                                        <input className='form-control my-2' type="email" name='email' id='email' placeholder='Email' />
                                        <p className='text-danger text-start'></p>
                                        <input className='form-control my-2' type="password" name='password' id='password' placeholder='Password' />
                                        <p className='text-danger text-start'></p>

                                        {/* <button type='submit' className='btn btn-warning text-light w-100 rounded-2 mt-2'><i className='fa-solid fa-spinner fa-spin'></i></button> */}
                                        <Link to="clientspage"><button type='submit' className='btn btn-warning text-light w-100 rounded-2 mt-2'>Login</button></Link>
                                        <p className='pt-2'>Don't have account? <Link className='text-decoration-none' to='/'>Sign Up</Link></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
