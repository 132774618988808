import React from 'react'
import SaidBar from './SaidBar'
import { Link } from 'react-router-dom'
import $ from 'jquery'


export default function Clientspage() {

    $(window).on('resize', function () {
        if ($(window).width() < 1050) {
            $(".offcanvas").removeClass("show")
            $(".fa-xmark").removeClass("d-none")
        }
        else {
            $(".offcanvas").addClass("show")
            $(".fa-xmark").addClass("d-none")
        }
    });


    return (
        <>

            <div className="container">
                <div className="row my-3 justify-content-center">
                        <div className="col-6 offset-md-2">
                            <div className="d-flex mt-3">
                                <input type="text" placeholder='Search' className='form-control me-2 w-75 position-relative' />
                                <button className='btn btn-warning  rounded-3'>Search</button>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex justify-content-end mt-3">
                                <Link to={"/newclients"}><button className='btn btn-warning'>+Add New</button></Link>
                            </div>
                        </div>
                        <div className="col-sm-10 offset-md-2">
                        <hr />
                        </div>
                </div>
      
                <div className="row">
                    <div className="col-sm-12 col-md-10 offset-md-2">
                        <table class="table table-dark table-striped table-hover table-bordered table-responsive ">
                            <thead>
                                <tr>
                                    <th scope="col">Index</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Info</th>
                                    <th scope="col">More</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>
                                        <Link to='/Spouse'><button className='btn btn-warning m-1 p-1'>+ spouse</button></Link>
                                        <Link to='/depends'><button className='btn btn-outline-warning m-1 p-1'>+ depends</button></Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>
                                        <Link to='/Spouse'><button className='btn btn-warning m-1 p-1'>+ spouse</button></Link>
                                        <Link to='/depends'><button className='btn btn-outline-warning m-1 p-1'>+ depends</button></Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Jacob</td>
                                    <td>Jacob</td>
                                    <td>
                                        <Link to='/Spouse'><button className='btn btn-warning m-1 p-1'>+ spouse</button></Link>
                                        <Link to='/depends'><button className='btn btn-outline-warning m-1 p-1'>+ depends</button></Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
