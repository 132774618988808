import React from 'react'
import { Outlet } from 'react-router-dom'
import SaidBar from './SaidBar'

export default function Layout() {
    return (
        <>
            <SaidBar />
            <Outlet />
        </>
    )
}
